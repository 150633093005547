<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据统计</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back()">班级培训统计</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">机构资料</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-input
                v-model="compName"
                type="text"
                size="small"
                placeholder="请输入机构名称"
                clearable
              />
            </div>
            <div class="df" style="padding-right:15px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="downloadMaterial()"
                >下载机构资料</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
              />
              <el-table-column
                label="培训学员数量"
                align="left"
                show-overflow-tooltip
                prop="totalUser"
              />
              <el-table-column
                label="是否上传资料"
                align="left"
                show-overflow-tooltip
                prop="isUpload"
              >
                <template slot-scope="scope">
                  {{ scope.row.isUpload ? "是" : "否" }}
                </template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100px"
                fixed="right"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    size="mini"
                    style="padding:0 15px"
                    @click="lookupTrainCert(scope.row)"
                    >详情</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      title="查看机构资料"
      :visible.sync="dialogCert"
      width="50%"
      top="2%"
      center
      @close="closeDownloadCert"
    >
      <div class="ovy-a" style="height:600px">
        <el-form ref="ruleForm" label-width="200px" class="el_form">
          <el-form-item label="机构名称:" class="form-inline">
            <p>{{ ruleForm.compName }}</p>
          </el-form-item>
          <el-form-item label="培训期次:" class="form-inline">
            <p>{{ ruleForm.courseName }}</p>
          </el-form-item>
          <el-form-item label="单位介绍信:" prop="fileName">
            <div
              style="display: flex; flex-direction: column"
              v-if="
                ruleForm.unitIntroductionLetter &&
                  ruleForm.unitIntroductionLetter.length
              "
            >
              <span
                style="color: #409eff"
                v-for="(item, index) in ruleForm.unitIntroductionLetter"
                :key="index"
              >
                {{ item.pictureName }}
                <a @click="lookExl(item)" style="color: #5bb5ff;margin:0 15px"
                  >预览</a
                >
              </span>
            </div>
            <div v-else>无</div>
          </el-form-item>
          <el-form-item label="申请表:" prop="fileName">
            <div
              style="display: flex; flex-direction: column"
              v-if="ruleForm.applicationForm && ruleForm.applicationForm.length"
            >
              <span
                style="color: #409eff"
                v-for="(item, index) in ruleForm.applicationForm"
                :key="index"
              >
                {{ item.pictureName }}
                <a @click="lookExl(item)" style="color: #5bb5ff;margin:0 15px"
                  >预览</a
                >
              </span>
            </div>
            <div v-else>无</div>
          </el-form-item>
          <el-form-item label="其他材料:" prop="fileName">
            <div
              style="display: flex; flex-direction: column"
              v-if="ruleForm.otherMaterials && ruleForm.otherMaterials.length"
            >
              <span
                style="color: #409eff"
                v-for="(item, index) in ruleForm.otherMaterials"
                :key="index"
              >
                {{ item.pictureName }}
                <a @click="lookExl(item)" style="color: #5bb5ff;margin:0 15px"
                  >预览</a
                >
              </span>
            </div>
            <div v-else>无</div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 预览附件 - loading -->
    <el-dialog
      title="预览"
      :visible.sync="previewLoding1"
      width="50%"
      top="2%"
      center
    >
      <div class="ovy-a">
        <div
          id="pdf-cert1"
          style="height: 600px"
          v-if="fileType == 'pdf' || fileType == 'PDF'"
        ></div>
        <div v-else>
          <img :src="ImgSrc" alt="" width="100%" height="100%" />
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import pdf from "pdfobject";
export default {
  name: "ScreenCapture",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      compName: "",
      projectId: this.$route.query.projectId,
      dialogCert: false,
      ruleForm: {},
      previewLoding1: false,
      ImgSrc: "",
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compName) {
        params.compName = this.compName;
      }
      if (this.projectId) {
        params.projectId = this.projectId;
      }
      this.doFetch({
        url: "/gov/project/viewCompMaterial",
        params,
        pageNum,
      });
    },
    lookupTrainCert(row) {
      this.dialogCert = true;
      let parmar = {
        compId: row.compId,
        projectId: this.projectId,
      };
      this.$post(
        "/biz/projectCourse/material/getCompMaterialInfo",
        parmar
      ).then((res) => {
        this.ruleForm = {
          ...res.data,
        };
      });
    },
    closeDownloadCert() {
      this.dialogCert = false;
      this.ruleForm = {};
    },
    lookExl(item) {
      this.previewLoding1 = true;
      let extension = item.pictureKey.replace(/.+\./, "");
      this.fileType = extension;
      if (extension == "pdf" || extension == "PDF") {
        this.$nextTick(() => {
          pdf.embed(item.pictureUrl, "#pdf-cert1");
        });
      } else {
        this.ImgSrc = item.pictureUrl;
      }
    },
    //下载机构资料
    downloadMaterial() {
      let parmar = {
        compName: this.compName,
        projectId: this.projectId,
      };
      this.$post("/biz/projectCourse/material/downloadMaterial", parmar).then(
        (ret) => {
          if (ret.status == "0") {
            if (!this.downloadItems.includes(ret.data.data.taskId)) {
              this.$store.dispatch("pushDownloadItems", ret.data.data.taskId);
            } else {
              this.$message.warning(
                "[" + ret.data.data.fileName + "]已经申请下载,请耐心等待"
              );
            }
          } else {
            this.$message.error(ret.data.message);
          }
        }
      );
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    },
  },
};
</script>
<style lang="less">
.searchBox {
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
